import React from "react";
import { Link } from "react-router-dom";
import "./TopBar.css";

function TopBar() {
  return (

    <nav className="top-bar navigation-bar">
        <div>
                <img className="top-bar-logo" src="https://content.publishingconcepts.com/Common/CONnect/logos/SVG/CONnect logo_white.svg" alt="Logo" />
            </div>
      <ul className="menu-section">
        <li>
          <Link to="/" className="link-menu">Home</Link>  {/* Link to the Home route */}
        </li>
        <li>
          <Link to="/registration" className="link-menu">Registration</Link>  {/* Link to the Register route */}
        </li>
        <li>
          <Link to="/contact" className="link-menu">Contact</Link>  {/* Link to the Contact route */}
        </li>    
      </ul>
    </nav>
  );
}

export default TopBar;
