import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from './common/Spinner'; 

function Registration() {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    homeCity: "",
    homeZip: "",
    homeState: "",
  });

  const [states, setStates] = useState([]); // State for list of states
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [loading, setLoading] = useState(false); 

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data state
    setFormData({ ...formData, [name]: value });

    // Dynamically hide error if the input becomes valid
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value) ? false : prevErrors[name],
    }));
  };

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

   // Validate individual fields
   const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
      case "lastName":
      case "phoneNumber":
        return value.trim() !== ""; // Field should not be empty
      case "email":
        return /\S+@\S+\.\S+/.test(value); // Simple email validation
      default:
        return true;
    }
  };

  const resetForm = () => {
    setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        homeCity: "",
        homeZip: "",
        homeState: "",
    });
  };

   // Validate the form fields
   const validateForm = () => {
    let valid = true;
    let newErrors = {
      firstName: !formData.firstName,
      lastName: !formData.lastname,
      email: !/\S+@\S+\.\S+/.test(formData.email), // Simple email validation
      phoneNumber: !formData.phoneNumber,
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setError('');
    setSuccessMessage('');
    setLoading(true);


    if (validateForm()) {
      try {
        const response = await axios.post(API_URL + 'registration',{
              headers: {
                  'Subscription-Key': API_KEY
              }
           }, formData);
            
            if (response.status === 200) {
                setSuccessMessage('Your registration has been sent successfully.');
                setShowSuccessDialog(true); 
                resetForm();
            } else {
                setError('Something went wrong. Please try again.');
            }
      } catch (err) {
        setError('Error sending registration: ' + err.message);
      }
      finally {
        setLoading(false); 
      }
    } else {
      setLoading(false);
      console.log("Form validation failed");
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(API_URL + 'registration/states', {
          headers: {
              'Subscription-Key': API_KEY
          }
      });
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  const closeSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  return (
    <div>
<div className="parent-content">
            <div className="image-content-section-2">
                <img className="image-crowd" src="https://content.publishingconcepts.com/Common/CONnect/img_connect_registration.png" alt="Image" />
            </div>
            <div className="form-section-2">
                <p className="title">REGISTRATION</p>
                <form onSubmit={handleSubmit} id="contact-form" action="" autoComplete="on">
                    <label htmlFor="user_name" className="label">First Name*</label>
                    <input name="firstName" className="input" value={formData.firstName} onChange={handleChange}/>
                    {errors.firstName && (
                      <p id="firstnamecheck" className="error">
                        **First name is missing
                      </p>
                    )}

                    <label htmlFor="user_lname" className="label">Last Name*</label>
                    <input name="lastName" className="input" value={formData.lastName} onChange={handleChange}/>
                    {errors.lastName && (
                      <p id="firstnamecheck" className="error">
                        **Last name is missing
                      </p>
                    )}

                    <label htmlFor="user_email" className="label">Email*</label>
                    <input name="email" className="input" type="email" value={formData.email} onChange={handleChange} />
                    {errors.email && (
                      <p id="emailcheck" className="error">
                         **Email is not valid
                      </p>
                    )}
                    
                    <label htmlFor="pnumber" className="label">Phone Number*</label>
                    <input name="phoneNumber" className="input" value={formData.phoneNumber} onChange={handleChange} />
                    {errors.phoneNumber && (
                      <p id="phonenumbercheck" className="error">
                        **Phone number is missing
                      </p>
                    )}

                    <label htmlFor="addline1" className="label">Address Line 1</label>
                    <input name="addressLine1" className="input" value={formData.addressLine1} onChange={handleChange} />

                    <label htmlFor="addline2" className="label">Address Line 2</label>
                    <input name="addressLine2" className="input" value={formData.addressLine2} onChange={handleChange} />

                    <label htmlFor="homecity" className="label">Home City</label>
                    <input name="homeCity" className="input" value={formData.homeCity} onChange={handleChange} />
                    <div className="row">                     
                        <div className="column">
                            <label htmlFor="homeState" className="label">Home State</label>
                            <select
                            id="homeState"
                            name="homeState"
                            value={formData.homeState}
                            onChange={handleChange}
                            className="input-2"
                            >
                            <option value="">-- Select State --</option>
                            {states.map((state) => (
                            <option key={state.id} value={state.description}>
                            {state.description}
                            </option>
                            ))}
                            </select>
                        </div>
                        <div className="column">
                            <label htmlFor="homeZip" className="label">Home Zip</label>
                            <input name="homeZip" className="input" value={formData.homeZip} onChange={handleChange} />
                        </div>
                        
                    </div>
                   
                    
                    
                    <button type="submit" className="send-button">Register</button>

                     {/* Show spinner while loading */}
                     {loading && <Spinner />}

                      {successMessage && <p className="info-message success-message">{successMessage}</p>}
                      {error && <p className="error-message">{error}</p>}
                </form>
                
                <p className="text-bottom">
                    We value your privacy and are committed to protecting your personal information. Please be assured that we do not sell, rent, or lease your data to third parties. Your information is used solely to enhance your experience with us and to provide you with the services and support you request. We adhere to strict data protection policies to ensure your information remains confidential and secure. For more details on how we handle your data, please review our Privacy Policy.
                    <a  href="https://www.publishingconcepts.com/privacy-policy-0" target="_blank">
                        https://www.publishingconcepts.com/privacy-policy-0
                    </a><br/><br/>
                    By registering for this platform, you acknowledge and agree that any stories and photos you provide may be used for this project. Your submission grants us permission to utilize your work in accordance with our project goals. If you have any concerns or questions, please contact us at 
                    <a href="mailto:connect@publishingconcepts.com"  target="_blank">
                    connect@publishingconcepts.com.
                    </a>
                </p>
                {/* Success Dialog */}
                {showSuccessDialog && (
                    <div className="dialog-overlay">
                        <div className="dialog">
                            <p>{successMessage}</p>
                            <button onClick={closeSuccessDialog} className="close-button">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>

     
    </div>
  );
}

export default Registration;
