import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from './common/Spinner'; 

function Contact() {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [contactInfo, setContactInfo] = useState("");

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); 

  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phonenumber: false,
    message: false,
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);


  useEffect(() => {
    axios.get(API_URL + '/contact',{
      headers: {
          'Subscription-Key': API_KEY
      }
   })
      .then(response => setContactInfo(response.data))
      .catch(error => console.error("There was an error!", error));
  }, []);

  // Handle input change and dynamic error validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data state
    setFormData({ ...formData, [name]: value });

    // Dynamically hide error if the input becomes valid
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value) ? false : prevErrors[name],
    }));
  };


  // Validate the form fields
  const validateForm = () => {
    let valid = true;
    let newErrors = {
      firstname: !formData.firstname,
      lastname: !formData.lastname,
      email: !/\S+@\S+\.\S+/.test(formData.email), // Simple email validation
      phonenumber: !formData.phonenumber,
      message: !formData.message,
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error); 
  };

  // Reset form after successful submission
  const resetForm = () => {
    setFormData({
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        message: ''
    });
   };

  // Validate individual fields
  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstname":
      case "lastname":
      case "phonenumber":
      case "message":
        return value.trim() !== ""; // Field should not be empty
      case "email":
        return /\S+@\S+\.\S+/.test(value); // Simple email validation
      default:
        return true;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Clear previous error messages
    setError('');
    setSuccessMessage('');
    setLoading(true);
    
    if (validateForm()) {
      try {
        const response = await axios.post(API_URL + 'contact/send',
          formData,
          {headers: {'Subscription-Key': API_KEY}}
        );
            
        if (response.status === 200) {
            setSuccessMessage('Your message has been sent successfully.');
            setShowSuccessDialog(true); 
            resetForm();
        } else {
            setError('Something went wrong. Please try again.');
        }
      } catch (err) {
        setError('Error sending message: ' + err.message);
      }
      finally {
        setLoading(false); // Hide the spinner when submission finishes
      }
    } else {
        setLoading(false);
      console.log("Form validation failed");
    }
  };

  const closeSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  return (
    <div className="parent-content">
    <div className="form-section">
      <p className="title">CONTACT</p>
      <form id="contact-form" onSubmit={handleSubmit} autoComplete="on">
        {/* First Name */}
        <label htmlFor="firstname" className="label">
          First Name*
        </label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          value={formData.firstname}
          onChange={handleInputChange}
          className="input"
        />
        {errors.firstname && (
          <p id="firstnamecheck" className="error">
            **First name is missing
          </p>
        )}

        {/* Last Name */}
        <label htmlFor="lastname" className="label">
          Last Name*
        </label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          value={formData.lastname}
          onChange={handleInputChange}
          className="input"
        />
        {errors.lastname && (
          <p id="lastnamecheck" className="error">
            **Last name is missing
          </p>
        )}

        {/* Email */}
        <label htmlFor="email" className="label">
          Email*
        </label>
        <input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="input"
        />
        {errors.email && (
          <p id="emailcheck" className="error">
            **Email is not valid
          </p>
        )}

        {/* Phone Number */}
        <label htmlFor="phonenumber" className="label">
          Phone Number*
        </label>
        <input
          type="text"
          id="phonenumber"
          name="phonenumber"
          value={formData.phonenumber}
          onChange={handleInputChange}
          className="input"
        />
        {errors.phonenumber && (
          <p id="phonenumbercheck" className="error">
            **Phone number is missing
          </p>
        )}

        {/* Message */}
        <label htmlFor="message" className="label">
          Message*
        </label>
        <textarea
          id="message"
          name="message"
          rows="10"
          value={formData.message}
          onChange={handleInputChange}
          className="text-area"
        ></textarea>
        {errors.message && (
          <p id="messagecheck" className="error">
            **Message is missing
          </p>
        )}

        {/* Submit button */}
        <input type="submit" value="Send" className="send-button" disabled={loading}/>
      </form>

       {/* Show spinner while loading */}
       {loading && <Spinner />}

      {successMessage && <p className="info-message success-message">{successMessage}</p>}
      {error && <p className="error-message">{error}</p>}
    </div>
    <div className="image-content-section">
         <img className="image-crowd" src="https://content.publishingconcepts.com/Common/CONnect/NYCC_crowd.png" alt="Image" />
     </div>

     {/* Success Dialog */}
     {showSuccessDialog && (
                <div className="dialog-overlay">
                    <div className="dialog">
                        <p>{successMessage}</p>
                        <button onClick={closeSuccessDialog} className="close-button">Close</button>
                    </div>
                </div>
            )}
  </div>
  );
}

export default Contact;
