import React, { useEffect, useState } from "react";
import axios from "axios";

function Home() {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [message, setMessage] = useState("");


  useEffect(() => {
    axios.get(API_URL + "home", {
      headers: {
          'Subscription-Key': API_KEY
      }
  })
      .then(response => setMessage(response.data))
      .catch(error => console.error("There was an error!", error));
  }, []);

  return (
    <div>
        <div>
            <img className="banner" src="https://content.publishingconcepts.com/Common/CONnect/CONnect_website_banner.jpg" alt="Banner" />
        </div> 
        <div className="parent-content">
            <div className="form-section">
                <p className="title">WHY?</p>
                <p className="text-info">
                    Conventions go by in a blink. Memories fade, photos are deleted, contact information gets lost.
                    <br/><br/>
                    What better way to commemorate this year's con than in a full color, oversized hardbound book that
                    fits perfectly among your Absolutes and collector editions?
                    <br/><br/>
                    Each publication will feature stories from hundreds of attendees, creators, and artists, as well as
                    photos capturing special moments from the weekend. We will also include social media details for you
                    to easily reach out and connect with others.
                    <br/><br/>
                    At Convention Connection, we know how much people cherish their time at conventions. This is your opportunity to make sure your 
                    memories never fade and get another chance to build connections with fellow fans you may not have already met.
                </p>
                <p className="title">HOW IT WORKS</p>
                <p className="text-info">
                    Tell us your favorite convention story – is this your first con? 20th? Did the entire family 
                    cosplay with you this year? What creator were you most excited to meet? We’ll turn your story 
                    into text and ask you to share your number one photo from the weekend.
                    <br/><br/>
                    Not good on the spot? We'll give you some time to think. Just call our dedicated number and 
                    leave your story as a message. We'll transcribe it and send it back for review. Introverts, 
                    we've got you covered. Simply submit a written story and photo through our site, no awkward social 
                    interaction required.
                    <br/><br/>
                    Your story and photo will be included in the book along with the others we collect over the weekend. We’ll 
                    also give you the opportunity to pre-order the publication to be shipped directly to you as soon as it's 
                    printed. The only thing you’ll need to do is make some space on your bookshelf.
                </p>
            </div>
            <div className="image-content-section">
                    <img className="image-crowd" src="https://content.publishingconcepts.com/Common/CONnect/NYCC_crowd.png" alt="Image" />
            </div>
        </div>
        <div className="text-footer">
            © Convention Connection 2024
        </div>
      <p>{message}</p>
    </div>
  );
}

export default Home;