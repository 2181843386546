import React from "react";
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import './App.css'; 
import Home from "./components/Home";
import Contact from "./components/Contact";
import Registration from "./components/Registration";
import TopBar from "./components/common/TopBar";  

function App() {
  return (
    <>
     {/* Render the TopBar at the top of the page */}
     <TopBar />
     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/registration" element={<Registration />} />

         {/* Redirect any unknown route to Home */}
         <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App; 
